import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 10 10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#000",
      "fill-rule": "evenodd",
      d: "M4.375 1.25a3.125 3.125 0 1 0 0 6.25 3.125 3.125 0 0 0 0-6.25M0 4.375a4.375 4.375 0 1 1 7.953 2.518L9.78 8.72a.75.75 0 0 1-1.06 1.06L6.893 7.953A4.375 4.375 0 0 1 0 4.375m5-.625h.875a.625.625 0 0 1 0 1.25H5v.875a.625.625 0 0 1-1.25 0V5h-.875a.625.625 0 0 1 0-1.25h.875v-.875a.625.625 0 0 1 1.25 0z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }