export default function (customConfig: ImgIxQueryParamConfig) {
  const defaultConfig: ImgIxQueryParamConfig = {
    w: undefined,
    h: undefined,
    fit: undefined,
    crop: undefined,
    q: 30,
    bg: undefined,
    pad: undefined,
    fillColor: undefined,
  }

  const mergedConfig = { ...defaultConfig, ...customConfig }

  const auto = '?auto=compress,format'
  const w = mergedConfig.w ? `&w=${mergedConfig.w}` : ''
  const h = mergedConfig.h ? `&h=${mergedConfig.h}` : ''
  const q = mergedConfig.q ? `&q=${mergedConfig.q}` : ''
  const fit = mergedConfig.fit ? `&fit=${mergedConfig.fit}` : ''
  const crop = mergedConfig.crop ? `&crop=${mergedConfig.crop}` : ''
  const bg = mergedConfig.bg ? `&bg=${mergedConfig.bg}` : ''
  const pad = mergedConfig.pad ? `&pad=${mergedConfig.pad}` : ''
  const fillColor = mergedConfig.fillColor ? `&fill-color=${mergedConfig.fillColor}` : ''

  const queryParams = `${auto}${w}${h}${q}${fit}${crop}${bg}${pad}${fillColor}`
  return queryParams
}
